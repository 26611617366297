class carModalDetailComponent extends Component {

    static name() {
        return "carModalDetailComponent";
    }

    static componentName() {
        return "carModalDetailComponent";
    }

    getProps() {
        return ['item','backToList','closeCallback'];
    }

    getComputed(){
        return {
            itemStore:function(){
                return this.$store.getters.getItemByCode(this.item.fields.ArtCode);
            },
            Currency:function () {
                return this.$store.getters.getCurrency;
            }
        };
    }

    created() {
        return function () {
            this.subscribeEvent('modal-detail-open',this.modalOpen);
        };
    }

    getMethods() {
        return {
            modalOpen:this.modalOpen,
            modalClose:this.modalClose
        };
    }

    modalOpen(){
        $("#carItemDetailModal").modal('show');
    }

    modalClose(){
        if(this.closeCallback)
            this.closeCallback.call();
        this.emitEvent('modal-detail-close');
    }

    getTemplate() {
        return `<div :key="item.Code" class="modal fade item-detail-modal" id="carItemDetailModal" tabindex="-1" role="dialog">
                  <div class="modal-dialog modal-lg" role="document">
                      <div class="modal-content">
                          <div class="modal-header">
                              <h5 class="modal-title">{{tr("Item Detail")}}</h5>
                              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                  <span aria-hidden="true">&times;</span>
                              </button>
                          </div>
                          <div class="modal-body">
                              <div class="row">
                                  <div class="col-md-4 col-sm-12 text-center text-md-left mb-3 md-md-0">
                                      <itemSliderComponent :item="itemStore" :container="false" :showCarusel="true" :itemInSlider="1"></itemSliderComponent>
                                  </div>
                                  <div class="col-md-8 col-sm-12">
                                      <h5>{{itemStore.Name}}</h5>
                                      <p>{{itemStore.Comment2}}</p>
                                      <p class="item-price"><strong>$ {{parseFloat(itemStore.Price || 0).toFixed(2)}}</strong><small v-if="Currency"> {{Currency}}</small></p>
                                      <!--button class="btn green btn-flat white-text" @click="backToList(item)" data-dismiss="modal">{{tr('Back to List')}}</button-->
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
               </div>`;
    }
}

carModalDetailComponent.registerComponent();